import React from "react";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import StackGrid from "react-stack-grid";
import { Item, Gallery } from "react-photoswipe-gallery";

import io0019 from './photo/0019.jpg';
import it0019 from './photo/0019t.jpg';
import io0018 from './photo/0018.jpg';
import it0018 from './photo/0018t.jpg';
import io0017 from './photo/0017.jpg';
import it0017 from './photo/0017t.jpg';
import io0016 from './photo/0016.jpg';
import it0016 from './photo/0016t.jpg';
import io0015 from './photo/0015.jpg';
import it0015 from './photo/0015t.jpg';
import io0014 from './photo/0014.jpg';
import it0014 from './photo/0014t.jpg';
import io0013 from './photo/0013.jpg';
import it0013 from './photo/0013t.jpg';
import io0012 from './photo/0012.jpg';
import it0012 from './photo/0012t.jpg';
import io0011 from './photo/0011.jpg';
import it0011 from './photo/0011t.jpg';
import io0010 from './photo/0010.jpg';
import it0010 from './photo/0010t.jpg';
import io0009 from './photo/0009.jpg';
import it0009 from './photo/0009t.jpg';
import io0008 from './photo/0008.jpg';
import it0008 from './photo/0008t.jpg';
import io0007 from './photo/0007.jpg';
import it0007 from './photo/0007t.jpg';
import io0006 from './photo/0006.jpg';
import it0006 from './photo/0006t.jpg';
import io0005 from './photo/0005.jpg';
import it0005 from './photo/0005t.jpg';
import io0004 from './photo/0004.jpg';
import it0004 from './photo/0004t.jpg';
import io0003 from './photo/0003.jpg';
import it0003 from './photo/0003t.jpg';
import io0002 from './photo/0002.jpg';
import it0002 from './photo/0002t.jpg';
import io0001 from './photo/0001.jpg';
import it0001 from './photo/0001t.jpg';


const MyGallery = () => {
  return (
    <Gallery shareButton={false} id="1" options={{ preload: [1, 2] }}>
      <StackGrid columnWidth={300} gutterWidth={10} gutterHeight={10} appearDelay={275} monitorImagesLoaded={true}>
        <Item
          original={io0019}
          thumbnail={it0019}
          width="2560"
          height="1707"
          title="Tricolor"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0019} alt="Tricolor" />}
        </Item>
        <Item
          original={io0018}
          thumbnail={it0018}
          width="2560"
          height="1707"
          title="Overview"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0018} alt="Overview" />}
        </Item>
        <Item
          original={io0017}
          thumbnail={it0017}
          width="2560"
          height="1548"
          title="Hurdles"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0017} alt="Hurdles" />}
        </Item>
        <Item
          original={io0016}
          thumbnail={it0016}
          width="2560"
          height="1707"
          title="Illuminated"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0016} alt="Illuminated" />}
        </Item>
        <Item
          original={io0015}
          thumbnail={it0015}
          width="2560"
          height="1707"
          title="Breadwinner"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0015} alt="Breadwinner" />}
        </Item>
        <Item
          original={io0014}
          thumbnail={it0014}
          width="2560"
          height="1707"
          title="Child"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0014} alt="Child" />}
        </Item>
        <Item
          original={io0013}
          thumbnail={it0013}
          width="2560"
          height="1707"
          title="Crossed"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0013} alt="Crossed" />}
        </Item>
        <Item
          original={io0012}
          thumbnail={it0012}
          width="2560"
          height="1707"
          title="💕"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0012} alt="💕" />}
        </Item>
        <Item
          original={io0011}
          thumbnail={it0011}
          width="2560"
          height="1598"
          title="Lone"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0011} alt="Lone" />}
        </Item>
        <Item
          original={io0010}
          thumbnail={it0010}
          width="2560"
          height="1707"
          title="Balos"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0010} alt="Balos" />}
        </Item>
        <Item
          original={io0009}
          thumbnail={it0009}
          width="2560"
          height="1707"
          title="Cattedrale di Santa Maria del Fiore"
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src={it0009}
              alt="Cattedrale di Santa Maria del Fiore"
            />
          )}
        </Item>
        <Item
          original={io0008}
          thumbnail={it0008}
          width="2560"
          height="1707"
          title="Duel"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0008} alt="Duel" />}
        </Item>
        <Item
          original={io0007}
          thumbnail={it0007}
          width="2560"
          height="1275"
          title="Coast"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0007} alt="Coast" />}
        </Item>
        <Item
          original={io0006}
          thumbnail={it0006}
          width="1707"
          height="2560"
          title="Ushanka"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0006} alt="Ushanka" />}
        </Item>
        <Item
          original={io0005}
          thumbnail={it0005}
          width="2560"
          height="1707"
          title="Green"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0005} alt="Green" />}
        </Item>
        <Item
          original={io0004}
          thumbnail={it0004}
          width="2560"
          height="1707"
          title="🌴"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0004} alt="🌴" />}
        </Item>
        <Item
          original={io0003}
          thumbnail={it0003}
          width="2560"
          height="1707"
          title="Сoralline"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0003} alt="Сoralline" />}
        </Item>
        <Item
          original={io0002}
          thumbnail={it0002}
          width="2560"
          height="1707"
          title="Lighthouse"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0002} alt="Lighthouse" />}
        </Item>
        <Item
          original={io0001}
          thumbnail={it0001}
          width="2560"
          height="1707"
          title="White"
        >
          {({ ref, open }) => <img ref={ref} onClick={open} src={it0001} alt="White" />}
        </Item>
      </StackGrid>
    </Gallery>
  );
};

export default MyGallery;
